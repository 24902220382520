import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isMobileDevice } from "../../helper/isMobileDevice";

import unchecked from '../../images/empty-tick.png'
import checked from '../../images/checked.svg'
import password_hidden from '../../images/password-hover.svg';
import password_visible from '../../images/password-enabled.svg';
import back_arrow from '../../images/back_arrow.svg';

const ForgotPassword = ({
    onChange,
    verification_code,
    requesting_password,
    verifyResetPasswordCode,
    setPassword,
    length_flag,
    space_flag,
    password,
    password_flag,
    case_flag,
    email,
    verified_user,
    togglePasswordFlag,
    backToLogin,
    verfication_code_requested,
    rememberPasswordToggle,
    remember_password,
    disable_verify_button,
    requestVerficationCode,
    disable_resend_button,
    login_in_progress,
    code_verification_in_progress
}) => {
    return (
        <div className="verification-form">
            <span onClick={backToLogin} className="back-button pointer">
                <img src={back_arrow} alt="backbutton" />
            </span>
            <div className="progress-bar mg-bottom-20 has-text-centered">
                <span className={classnames('step step1', { 'active': verfication_code_requested, 'active passed': verified_user })} >
                    {verified_user ? <img width="13px" src={checked} title="step-completed" alt="step-completed" /> :
                        1
                    }
                </span>
                <span className={classnames('step step2', { 'active': verified_user })}>
                    2
                </span>
            </div>
            {
                !verified_user ?
                    <div className="verify-email" onSubmit={verifyResetPasswordCode}>
                        <h1 className={classnames('has-text-centered mg-bottom-10', { 'is-size-4': !isMobileDevice(), 'is-size-5': isMobileDevice() })}>
                            <strong className="fs-exclude-data has-text-grey-dark">Verify your Email</strong>
                        </h1>
                        <p className="has-text-centered font-14">Just one quick check to make sure you’re really you. We’ve sent a verification code to <strong>{email}</strong> <br/> <i>(Remember to check your Spam)</i></p>
                        <div className="verification mg-top-30 mg-bottom-30">
                            <div className="control">
                                <input className="input fs-exclude-data" autoFocus onChange={onChange.bind(this, 'verification_code')} type='text' value={verification_code} placeholder="Enter verification code" />
                            </div>
                        </div>
                        <p className="no-padding form-actions">
                            <span disabled={disable_resend_button} className={classnames('forgot-text is-size-7', {'is-disabled': disable_resend_button})} onClick={requestVerficationCode.bind(this, false)}> Didn't receive an email?</span>
                            <button disabled={disable_verify_button} className={classnames('button is-link', { 'is_loading': code_verification_in_progress })} onClick={verifyResetPasswordCode}>
                                Verify
                            </button>
                        </p>
                    </div>
                    :
                    <div className="reset-password" onSubmit={setPassword}>
                        <h1 className={classnames('has-text-centered mg-bottom-10', { 'is-size-4': !isMobileDevice(), 'is-size-5': isMobileDevice() })}>
                            <strong className="fs-exclude-data has-text-grey-dark">Reset Password</strong>
                        </h1>
                        <p className="font-14 has-text-centered">Don’t worry, happens to the best of us :)</p>
                        <div className="verification mg-top-30 mg-bottom-30">
                            <div className="control mg-bottom-30">
                                <input className="input fs-exclude-data" autoFocus onChange={onChange.bind(this, 'password')} type={password_flag ? 'password' : 'text'} value={password} placeholder="Enter Password" />
                                <button type="button" className="button is-white is-eye" onClick={togglePasswordFlag}>
                                    <span><img src={(password_flag ? password_visible : password_hidden)} alt="password" /></span>
                                </button>
                            </div>
                            <ul className="control is-size-7 mg-bottom-20">
                                <li className="mg-bottom-5">{length_flag ? <img width="10px" className="tick-img mg-right-5" src={checked} alt="tick" /> : <img width="10px" className="tick-img mg-right-5" src={unchecked} alt="tick" />}Password should be between 8 and 25 characters.</li>
                                <li className="mg-bottom-5">{case_flag ? <img width="10px" className="tick-img mg-right-5" src={checked} alt="tick" /> : <img width="10px" className="tick-img mg-right-5" src={unchecked} alt="tick" />}With one digit, one lower case & one upper case letter.</li>
                                <li className="mg-bottom-5">{space_flag ? <img width="10px" className="tick-img mg-right-5" src={checked} alt="tick" /> : <img width="10px" className="tick-img mg-right-5" src={unchecked} alt="tick" />}Should not contain any spaces.</li>
                            </ul>

                        </div>
                        <p className="no-padding form-actions">
                        <label className="checkbox is-size-7 is-info">
                            <input type="checkbox" checked={remember_password} onChange={rememberPasswordToggle} className="mg-right-5 mg-top-10"/>
                            <span className="has-text-grey-dark font-14">Remember me</span>
                        </label>
                            <button disabled={!(length_flag && case_flag && space_flag) || login_in_progress} className={classnames('button is-link', { 'is_loading': login_in_progress })} onClick={setPassword}>
                                Login
                            </button>
                        </p>
                    </div>
            }
        </div>
    );
};

// Porptypes
// ---------
ForgotPassword.propTypes = {
    onChange: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired
};

// default props
// -------------
ForgotPassword.defaultProps = {

};

export default ForgotPassword;
