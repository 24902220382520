import React, { Component } from 'react';
import map from 'lodash.map';
import { navigate } from "gatsby";
import classnames from 'classnames';
import axios from '../../helper/Axios';
import { looseFocus } from '../../helper/LooseFocus';
import { NotificationManager } from 'react-notifications';
import infeedo_logo from '../../images/infeedo-logo.svg';
import 'react-notifications/lib/notifications.css';
import config from '../../../config';
import ForgotPassword from './ForgotPassword';
import password_hidden from '../../images/password-hover.svg';
import password_visible from '../../images/password-enabled.svg';
import amber_hi from '../../images/amber_hi.png';
import { isMobileDevice } from "../../helper/isMobileDevice";
import { getServiceRegion } from '../../helper/getApiBase';
import OAuth from './OAuth';
import SSOLogin from './SSOLogin';
import ReactTooltip from 'react-tooltip';
import handleException from '../../helper/errorHandling';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default class Login extends Component {

    state = {
        email: '',
        password: '',
        checked: true,
        password_flag: true,
        length_flag: false,
        case_flag: false,
        space_flag: false,
        verfication_code_requested: false,
        verification_code: undefined,
        user_id: undefined,
        verified_user: false,
        requesting_password: false,
        disable_forgot_password: true,
        disabled_set_password: true,
        remember_password: false,
        disable_verify_button: true,
        disable_resend_button: false,
        sso_login_form_visible: false,
        sso_login_in_progress: false,
        login_in_progress: false,
        code_verification_in_progress: false
    }

    componentDidMount() {
        if (isMobileDevice()) {
            this.setState({ checked: true });
        }

        if(localStorage.token) {
            window.location = `${config.beta_dashboard_url}/${localStorage.token}`
        }
    }

    // XOR helper method
    // -----------------
    keyCharAt(key, i) {
        return key.charCodeAt(Math.floor(i % key.length));
    }

    // XOR encrypt password
    // --------------------
    xor_encrypt(key, data) {
        return map(data, (c, i) => {
            return c.charCodeAt(0) ^ this.keyCharAt(key, i);
        });
    }

    // login method
    // ------------
    login = async (e) => {
        e.preventDefault();
        looseFocus();
        this.setState({ login_in_progress: true });
        const { email, password, checked } = this.state;
        let encrypted_password = this.xor_encrypt('rkjbflaejrbgbjefaljkbewf', password);
        try {

            let open_url = checked ? "beta_dashboard" : "dashboard";
            sessionStorage.removeItem("current_region_url");
            await getServiceRegion(email, open_url);
            const response = await axios.post('/v1/authenticate', { email: email, password: encrypted_password })
            const role = response.data.profile.role;
            const password_set = response.data.profile.passwordSet;
            const token = response.data.token;
            if(this.state.remember_password) {
                localStorage.setItem('token', response.data.token)
            }
            this.redirectUser(checked, token, password_set, role)
        } catch (error) {
            if (error.response) {
                if (error.response.status === 429) {
                    NotificationManager.error('Too many login attempts');
                } else {
                    NotificationManager.error(error.response.data || 'Login Failed');
                }
            } else {
                NotificationManager.error('Something went wrong');
            }
        }
        this.setState({ login_in_progress: false });
    }

    redirectUser = (checked, token, password_set, role) => {
        if (!password_set) {
            navigate(`/redirect/?token=${token}&path=password-reset`)
        } else {
            switch (role) {
                case 1:
                    (checked ? window.location.href = `${config.beta_dashboard_url}/${token}` : window.location.href = `${config.dashboard_url}/#/token:${token}`);
                    break;
                case 2:
                    (checked ? window.location.href = `${config.beta_dashboard_url}/${token}` : window.location.href = `${config.dashboard_url}/#/token:${token}`);
                    break;
                case 3:
                    window.location.href = `${config.chat_url}/#/token:${token}`;
                    break;
                case 4:
                    (checked ? window.location.href = `${config.beta_dashboard_url}/${token}` : window.location.href = `${config.dashboard_url}/#/token:${token}`);
                    break;
                default:
                    NotificationManager.error('Something went wrong');
                    break;
            }
        }
    }

    // forgot password
    // ---------------
    forgotPassword = async (e) => {
        e.preventDefault();
        looseFocus();
        this.setState({ resetting: true })
        const { email } = this.state;
        try {
            sessionStorage.removeItem("current_region_url");
            await getServiceRegion(email, "dashboard");
            const response = await axios.post('/v1/users/resetpassword', { email });
            NotificationManager.success(response.data.message);
        } catch (error) {
            if (error.response) {
                NotificationManager.error(error.response.data.message || 'Reset Passowrd Failed');
            } else {
                NotificationManager.error('Something went wrong');
            }
        }
        this.setState({ resetting: false})
    }

    // On change input values map to state
    // -----------------------------------
    onChange = (which, e) => {
        if (which === 'password') {
            let { password } = this.state;
            password = e.target.value;
            this.setState({ password }, async () => await this.checkValidations())
        } else if(which === 'email') {
            let { email } = this.state;
            email = e.target.value;
            this.setState({ email}, () => this.validateEmail())

        } else {
            let min_response =  e.target.value;
            let numbers = /^[0-9]+$/;
            if(min_response.match(numbers) || min_response === ""){
                let {verification_code} = this.state
                verification_code = e.target.value
                this.setState({verification_code, disable_verify_button : verification_code.length > 5 ? false : true})
            }
        }
    };

    validateEmail = _ => {
        const {email}  = this.state

        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if(email.match(reg)) {
            this.setState({ disable_forgot_password: false})
        } else {
            this.setState({ disable_forgot_password: true})
        }
    }

    checkValidations = async() => {
        const { password} = this.state;
        const regularExpression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{1,25}$/;
        if ((password.length > 7) && password.length < 25) {
            this.setState({ length_flag: true });
        } else {
            this.setState({ length_flag: false });
        }
        if (regularExpression.test(password)) {
            this.setState({ case_flag: true });
        } else {
            this.setState({ case_flag: false });
        }
        if (!password.includes(' ') && password.length > 0) {
            this.setState({ space_flag: true });
        } else {
            this.setState({ space_flag: false });
        }
    }

    // cehck uncheck beta switch
    // -------------------------
    handleChange = (checked) => {
        this.setState({ checked });
    }

    // toggle password flag
    // --------------------
    togglePasswordFlag = () => {
        const { password_flag } = this.state;
        this.setState({ password_flag: !password_flag });
    }

    OAuth = (which) => {
        trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Login",
            // string - required - Type of interaction (e.g. 'play')
            action: "OAuth Login",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label:  `OAuth Login with ${which}`
          })
        window.location = `${config.url}auth/${which}`
    }

    requestVerficationCode = async (is_first_time) => {
        this.setState({disable_forgot_password: true})
        if(!this.state.disable_forgot_password) {
            const {email} = this.state
            if(email) {
                this.setState({ requesting_password: true });
                sessionStorage.removeItem("current_region_url");
                await getServiceRegion(email, 'beta_dashboard');
                try {
                    let response = await axios.post('/v1/users/get_verification_code', {email});
                    this.setState({
                        verfication_code_requested: true, 
                        user_id: response.data.data.user_id, 
                        password: '', 
                        disable_forgot_password: false,
                        length_flag: false,
                        case_flag: false,
                        space_flag: false
                    })
                    if(!is_first_time) {
                        this.setState({disable_resend_button: true})
                        trackCustomEvent({
                            // string - required - The object that was interacted with (e.g.video)
                            category: "Reset Password",
                            // string - required - Type of interaction (e.g. 'play')
                            action: "Didn't recieve Verification code",
                            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                            label:  `${email} clicked didn't recieve Verification code`
                        })
                        NotificationManager.info(`A verification code has been sent to ${email}`)
                        setTimeout(() => { this.setState({disable_resend_button: false}) }, 10000);
                    }
                    trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "Reset Password",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "Verification Code Requested",
                        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                        label:  `Verification Code Requested for ${email}`
                    })
                } catch (error){
                    handleException(error);
                }
            } else {
                NotificationManager.error('Please enter your email to reset your password')
            }
        }
    }

    setPassword = async () => {
        let {user_id, password} = this.state
        this.setState({login_in_progress: true})
        try {
            password = this.xor_encrypt('rkjbflaejrbgbjefaljkbewf', password);
            let response = await axios.post('/v1/users/changepassword', {password, user_id, 'has_password': true});
            if(response && response.status === 200 && response.data.data) {
                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Reset Password",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Password Reset Complete",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label:  `Password Reset Complete for ${user_id}`
                  })
                if(this.state.remember_password) {
                    localStorage.setItem('token', response.data.data)
                }
                this.redirectUser(true, response.data.data.token, true, response.data.data.role)
            } else {
                NotificationManager.info('We are facing some trouble in setting your password, Please try again in some time meanwhile we are diagnosing the cause.')
            }
        } catch (error) {
            if(error.response.data.code === 403) {
                NotificationManager.info('We are facing some trouble in setting your password, Please try again in some time meanwhile we are diagnosing the cause.')
            }
            this.setState({login_in_progress: false})
        }
        // this.setState({verfication_code_requested: true, user_id: response.data.data.user_id})
    }

    verifyResetPasswordCode = async _ => {
        let {verification_code, user_id} = this.state
        this.setState({code_verification_in_progress: true, disable_verify_button: true})
        try {
            const response = await axios.get('/v1/users/validate_verification_code', {params: {'code':verification_code, user_id}});
            NotificationManager.success(response.data.message); 
            if (response && response.status === 200) {
                this.setState({ verified_user: true })
            }
        } catch (error){
            this.setState({verification_code : '', code_verification_in_progress: false})
            NotificationManager.error(error.response.data.payload)
        }
        
    }

    backToLogin = _ => {
        trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Reset Password",
            // string - required - Type of interaction (e.g. 'play')
            action: "Back to Login",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label:  `Back to Login from Reset form`
          })
        this.setState({verfication_code_requested : false, verified_user: false, length_flag: false, case_flag: false, space_flag: false, verification_code: undefined, password: '', disable_forgot_password: false})
    }

    rememberPasswordToggle = _ => {
        trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
            category: "Login",
            // string - required - Type of interaction (e.g. 'play')
            action: "Remember Password",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label:  `Remember Password ${this.state.remember_password ? 'Enabled' : 'Disabled'}`
        })
        this.setState({remember_password : !this.state.remember_password})
    }

    toggleSSOForm = _ => {
        this.setState({sso_login_form_visible: !this.state.sso_login_form_visible})
    }

    SSOLogin = async() => {
        this.setState({sso_login_in_progress: true})
        try {
            const response = await axios.get('/v1/users/get_sso_url', {params: {'email':this.state.email}});
            
            if (response && response.status === 200) {
                this.setState({sso_login_in_progress: false})
                window.location = response.data.data.sso_url    
            }
        } catch (error){
            if (error.response.data.status === 404) {
                navigate(`/user-not-found/?user=${this.state.email}`)
            } else if(error.response.data.status === 500) {
                this.setState({sso_login_in_progress: false})
                NotificationManager.error(error.response.data.error)
            } else {
                handleException(error);
            }
        }
    }

    render() {
        const {email, password, password_flag, length_flag, case_flag, space_flag, verfication_code_requested, verification_code, verified_user, checked, requesting_password, disable_forgot_password, disabled_set_password, remember_password, disable_verify_button, disable_resend_button, sso_login_form_visible, sso_login_in_progress, login_in_progress, code_verification_in_progress } = this.state;
        return (
            <div className="login">
                <figure className="logo column mg-bottom-40 has-text-left pd-30">
                    <img src={infeedo_logo} alt="logo" className="mg-left-5" />
                </figure>
                <div className="card is-4-desktop is-offset-6-desktop column is-two-thirds-tablet is-offset-3-tablet is-10-mobile is-offset-1-mobile">
                    <div className="card-content">
                        {
                            !sso_login_form_visible ?
                                <>
                                    {!verfication_code_requested ?
                            <div className="">
                                <h1 className={classnames('has-text-centered mg-bottom-20', { 'is-size-4': !isMobileDevice(), 'is-size-5': isMobileDevice() })}>
                                    <strong className="fs-exclude-data has-text-grey-dark">Login to your <br/>Culture Dashboard</strong>
                                </h1>
                                <form onSubmit={this.login} className="secure-login-password">
                                    <div className="field">
                                        <div className="control mg-bottom-15">
                                            <input className="input fs-exclude-data" autoFocus onChange={this.onChange.bind(this, 'email')} type='email' value={email} placeholder="Enter Email" />
                                        </div>
                                        <div className="control">
                                            <input className="input fs-exclude-data" onChange={this.onChange.bind(this, 'password')} type={password_flag ? 'password' : 'text'} value={password} placeholder="Enter Password" />
                                            <button type="button" className="button is-white is-eye" onClick={this.togglePasswordFlag}>
                                                <span><img src={(password_flag ? password_visible : password_hidden)} alt="password" /></span>
                                            </button>
                                            {/* {login_attempt > 2 ? <small className="is-size-7 has-text-danger">In case you'd like to reset your password, please click on "I've forgotten my password"</small> : ''} */}
                                        </div>
                                    </div>
                                    <p className="no-padding form-actions mg-top-20">
                                    <label className="checkbox is-info">
                                        <input type="checkbox" checked={remember_password} onChange={this.rememberPasswordToggle} className="mg-right-5"/>
                                        <span className="has-text-grey-dark font-14">Remember me</span>
                                    </label>
                                        <span className="pointer forgot-password-container" data-for="forgot-tooltip" data-tip={!disable_forgot_password ? `Request password for ${email}` : `${email ? 'Please enter a valid email to reset your password' : 'Please enter your email to reset password'}`}>
                                            <span disabled={disable_forgot_password} className={classnames('fs-exclude-data font-14 forgot-text pointer mg-top-3', {'is-disabled': !email})} onClick={this.requestVerficationCode}>Forgot Password?
                                            </span>
                                        </span>
                                    </p>
                                    <p className="no-padding form-actions mg-top-20 single-button">
                                        <button onClick={this.login} className={classnames('button is-link', {'is-loading': login_in_progress})} type="submit" disabled={!email.length || !password.length}>
                                            Login
                                        </button>
                                    </p>
                                    {
                                        isMobileDevice() ? '' : 
                                        <ReactTooltip id="forgot-tooltip" className="previous-tooltip" effect="solid" place="bottom" html={true} />
                                    }
                                </form>
                            </div> :
                                <ForgotPassword 
                                    onChange = {this.onChange}
                                    verification_code = {verification_code}
                                    requesting_password = {requesting_password}
                                    verifyResetPasswordCode = {this.verifyResetPasswordCode}
                                    setPassword = {this.setPassword}
                                    length_flag = {length_flag}
                                    space_flag = {space_flag}
                                    checked = {checked}
                                    verified_user = {verified_user}
                                    case_flag = {case_flag}
                                    password_flag = {password_flag}
                                    email = {email}
                                    togglePasswordFlag = {this.togglePasswordFlag}
                                    backToLogin = {this.backToLogin}
                                    verfication_code_requested = {verfication_code_requested}
                                    disabled_set_password = {disabled_set_password}
                                    password = {password}
                                    remember_password = {remember_password}
                                    rememberPasswordToggle = {this.rememberPasswordToggle}
                                    disable_verify_button ={disable_verify_button}
                                    requestVerficationCode = {this.requestVerficationCode}
                                    disable_resend_button = {disable_resend_button}
                                    login_in_progress = {login_in_progress}
                                    code_verification_in_progress = {code_verification_in_progress}
                                />
                        }
                                </>
                                : 
                                <SSOLogin 
                                    email = {email}
                                    SSOLogin = {this.SSOLogin}
                                    onChange = {this.onChange}
                                    toggleSSOForm = {this.toggleSSOForm}
                                    sso_login_in_progress={sso_login_in_progress}
                                />
                        }
                        {!verfication_code_requested ?
                            <>
                            <OAuth
                                OAuth={this.OAuth}
                            />
                            <p className="mg-top-20 has-text-centered">
                                <span className="is-link pointer forgot-text" onClick={this.toggleSSOForm}>
                                    {sso_login_form_visible ? 'Login with Email and Password': 'Login with SSO'}
                                </span>
                            </p>
                            </>
                         : ''}
                    </div>
                </div>
                <img className="amber-hi" src={amber_hi} alt="amber_hi"/>
            </div>
        )
    }
}
    