import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginForm from "../components/login/Login";

const Login = () => (
  <Layout mode={true} extra_class={'login'}>
    <SEO title="Amber by inFeedo | Login" />
    <LoginForm/>
  </Layout>
)

export default Login