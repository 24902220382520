import React from 'react'
import classnames from 'classnames'
import { isMobileDevice } from "../../helper/isMobileDevice";


export default function SSOLogin({
    email,
    SSOLogin,
    onChange,
    sso_login_in_progress
}) {
    return (
        <form className="sso_login" onSubmit={SSOLogin}>
            <h1 className={classnames('has-text-centered mg-bottom-20', { 'is-size-4': !isMobileDevice(), 'is-size-5': isMobileDevice() })}>
                <strong className="fs-exclude-data has-text-grey-dark">Login to your <br/>Culture Dashboard</strong>
            </h1>
            <div className="field">
                <div className="control mg-bottom-15">
                    <input className="input fs-exclude-data" autoFocus onChange={onChange.bind(this, 'email')} type='email' value={email} placeholder="Enter Email" />
                </div>
                <p className="no-padding form-actions mg-top-20 single-button">
                    <button disabled={!email.length || sso_login_in_progress} onClick={SSOLogin} className={classnames('button is-link', {'is-loading ':  sso_login_in_progress})} type="submit">
                        Login with SSO
                    </button>
                </p>
            </div>
        </form>
    )
}
